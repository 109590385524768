.footer-container{
    padding: 50px 0px;
    background-color: #182659;
    color: var(--bg-light);
}

.footer-logo figure{
    height: 50px;
    width: 200px;
    margin: 0;
    padding: 0;
}

.footer-logo figure img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.footer-logo h2{
    font-weight: 400;
}

.footer-writeup{
    font-size: 18px;
    width: 90%;
}

.footer-social-media figure{
    height: 50px;
    width: 50px;
    border: 2px solid var(--bg-light);
    border-radius: 50%;
    padding: 0;
    margin: 0;
}

.footer-social-media figure img{
    height: 100%;
    width: 100%;
   object-fit: contain;
 
}

.footer-liat{
    list-style: none;
    line-height: 40px;
}

.link-tag{
    color: var(--bg-light);
    text-decoration: none;
}

.link-tag:hover{
   font-weight: 600;
   color: var(--bg-light);
   text-decoration: none;
}

.footer-copyRight{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-copyRight-secure  figure{
    height: 25px;
    width: 25px;
    margin: 0;
    padding: 0;
}

.footer-copyRight-secure figure img{
    height: 30px;
    width: 30px;
    object-fit: contain ;
}

.book{
    background-color: #00a859;
    color: white;
    text-decoration: none;
    border: none;
    padding: 15px 25px;
    border-radius: 15px;
    margin-bottom: 15px;
}

.book a{
    text-decoration: none;
    color: white;
    font-size: 18px;
    font-weight: bold;
}