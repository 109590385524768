
.best-seller-card figure{
    height: 240px;
    border-radius: 30px;
    overflow: hidden;
}

.best-seller-card figure img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.best-seller-name-and-price{
    margin: 10px 0px;
}

.best-seller-name-and-price button{
    padding: 5px 7px;
    border: none;
    border-radius: 20px;
    background-color: var(--bg-green);
    font-weight: 700;
    font-size: 18px;
}

.best-seller-btns{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.best-seller-btns button{
    border: 1px solid var(--bg-grey);
    background-color: transparent;
    padding: 5px;
    font-size: 18px;
    letter-spacing: 1px;
    border-radius: 5px;
    /* font-weight: 600; */

}

.best-seller-btns button:nth-child(2){
    width: 80%;
}