
.professional-card figure{
    height: 270px;
    width: 270px;
    border-radius: 50%;
    overflow: hidden;
}

.professional-card figure img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.professional-card h5{
    font-size: 18px;
}


.professional-card h6{
    font-size: 13px;
    font-weight: 600;
}

.rate figure{
    height: 20px ;
    width: 20px;
    /* margin: 0; */
    /* padding: 0; */
}

.rate figure img{
    height: 100% ;
    width: 100%;
    object-fit: contain;
 
}

.professional-card-btn{
    display: flex;
    justify-content: center;
}

.professional-card-btn button{
    height: 50px;
    width: 150px;
    border: none;
    border-radius: 10px;
    color: var(--bg-light);
    background-color: var(--bg-purple);
    font-weight: 600;
}