.home-banner1 h1{
    font-size: 2.2rem;
    font-weight: 700;
    width: 80%;
    /* margin-bottom: 40px; */
}


.home-banner1 p{
    font-size: 17px;
    width: 60%;
    margin: 20px 0px;
    /* font-weight: 5 00; */
}

.home-banner-left button{
    width: 250px;
    height: 55px;
    font-weight: 600;
    font-size: 20px;
    border: none;
    /* background-color: transparent; */
    border-radius: 30px;
    background-color: #182659;
    color: var(--bg-light);
}

.home-banner-right figure{
    height: 100%;
    width: 100%;
}

.home-banner-right figure img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.explore-container{
    margin-top:30px;
}

.explore-titles{
    font-size:35px;
}

.explore-button{
    padding: 8px 20px;
    border-radius: 30px;
    border: 1px solid var(--bg-dark);
    background-color: transparent;
    font-weight: 600;
}


.home-banner1 {
    position: relative;
    height: 100vh; 
    overflow: hidden;
    background: #00000044;
  }
  
  .background-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .text-container {
    position: absolute;
    top: 50%;
    left: 10%; /* Adjust the left position as needed */
    transform: translate(0, -50%);
    /* color: #fff; Text color */
  }

  .text-container2{
    color: white;
    position: absolute;
    top: 55%;
    left: 10%; /* Adjust the left position as needed */
    transform: translate(0, -50%);
  }
  